import React from 'react';
import { Button } from '@chakra-ui/react';
import { match } from 'ts-pattern';

import {
  AlertBox,
  BluetoothIcon,
  useResponsive,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { openSettingsPage } from '@strive/notifications';
import {
  StrivewareContext,
  StrivewareSelector,
  useStrivewareBattery,
} from '@strive/wearable';

export function DeviceAlerts() {
  const rs = useResponsive();
  const batteryQuery = useStrivewareBattery();
  const strivewareActor = StrivewareContext.useActorRef();

  const state = StrivewareContext.useSelector(
    StrivewareSelector.bluetoothState,
  );

  return (
    <>
      <WaitForQuery query={batteryQuery} loading={null} error={null}>
        {({ batteryStatus }) =>
          batteryStatus === 'low' && (
            <AlertBox
              gridArea="alert"
              showIcon
              variant="soft"
              status="error"
              title="Battery level is low."
              description="Charge your device before going to bed to ensure uninterrupted data sync."
            />
          )
        }
      </WaitForQuery>
      {match(state)
        .with('bluetooth.off', () => (
          <AlertBox
            gridArea="alert"
            showIcon={BluetoothIcon}
            variant="soft"
            status="warning"
            title="Bluetooth Disabled"
            description="Enable Bluetooth in your phone settings to establish a connection."
          />
        ))
        .with('permission.denied', () => (
          <>
            <AlertBox
              gridArea="alert"
              showIcon={BluetoothIcon}
              variant="soft"
              status="error"
              title="Bluetooth Permission Denied"
              description="Enable Bluetooth in your phone settings to establish a connection."
            />
            <Button
              onClick={() => openSettingsPage()}
              variant="black"
              size={rs({ base: 'lg', xs: 'sm' })}
            >
              Open Settings
            </Button>
          </>
        ))
        .with('permission.prompt', () => (
          <>
            <AlertBox
              gridArea="alert"
              showIcon={BluetoothIcon}
              variant="soft"
              status="error"
              title="Bluetooth Permission Prompt"
              description="Enable Bluetooth access for Arena Strive."
            />
            <Button
              onClick={() =>
                strivewareActor.send({ type: 'Request Permissions' })
              }
              variant="black"
            >
              Enable Bluetooth Access
            </Button>
          </>
        ))
        .with('bluetooth.ready', () => null)
        .exhaustive()}
    </>
  );
}
