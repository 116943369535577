import { useMemo } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';

import { LottieFile, useResponsive } from '@arena-labs/strive2-ui';

export function SetupScanning() {
  const rs = useResponsive();
  const animation = useMemo(
    () => import('./scanning-for-devices.lottie.json'),
    [],
  );
  return (
    <Flex direction="column" px="6" pt="4" alignItems="center" h="full">
      <LottieFile
        as={animation}
        boxSize={rs({ base: '280px', xs: '200px' })}
        my="auto"
      />
      <Flex align="center" gap="2" mb={4}>
        <Spinner size="sm" color={'neutral.white'} />{' '}
        <Text textStyle="copy_small" color={'neutral.white'}>
          Connecting...
        </Text>
      </Flex>
    </Flex>
  );
}
