import { PropsWithChildren, useEffect } from 'react';
import { Box, Button, Flex, IconButton } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import {
  ChevronLeftIcon,
  Icon,
  PageStack,
  useGradient,
  useResponsive,
} from '@arena-labs/strive2-ui';

import { AppBanner } from '../layout/app-banner';
import { SlideComponentOptions } from './wearable-hub';

interface StrivewareSubmenuProps extends PropsWithChildren {
  onBack: () => void;
  setSlide: (slide: SlideComponentOptions | null) => void;
}

export function StrivewareSubmenu({
  children,
  onBack,
  setSlide,
}: StrivewareSubmenuProps) {
  const rs = useResponsive();
  const bgGradient = useGradient('StriveWare.bgDarkGradient');
  const swiper = useSwiper();
  useEffect(() => {
    const handleSlideChange = () => {
      if (swiper.activeIndex === 0) {
        onBack();
      }
    };
    swiper.on('slideChange', handleSlideChange);
    return () => {
      swiper.off('slideChange', handleSlideChange);
    };
  }, [swiper, onBack]);
  return (
    <PageStack h={'full'} bg={bgGradient} px={4} pb={2}>
      <AppBanner ml={-4} mb={rs({ base: 0, xs: -4 })}>
        <IconButton
          aria-label="Back"
          onClick={() => {
            swiper.slideTo(0);
            onBack();
          }}
        >
          <Icon
            as={ChevronLeftIcon}
            boxSize="4"
            color={'neutral.white'}
            mr={'auto'}
          />
        </IconButton>
      </AppBanner>

      {children}
      <Flex
        mt={'auto'}
        w={'full'}
        gap={rs({ base: 4, xs: 3 })}
        flexDir={'column'}
      >
        <Button
          variant={'primary'}
          onClick={() => {
            swiper.slideTo(0);
            setSlide(null);
          }}
          w={'full'}
        >
          Return to Device
        </Button>
        <Box
          w={'120px'}
          h={'10px'}
          bg={'neutral.200'}
          mx={'auto'}
          rounded={'full'}
        />
      </Flex>
    </PageStack>
  );
}
