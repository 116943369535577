import { Fragment } from 'react';
import NextLink from 'next/link';
import { Box, Circle, Flex, Icon, Link, Text, VStack } from '@chakra-ui/react';
import { match, P } from 'ts-pattern';

import {
  AlertBox,
  formatRelativeTime,
  OuraLogoData,
  PageStack,
  PartnerDeviceIcon,
  WaitForQueries,
  WaitForQuery,
  WhoopLogoData,
} from '@arena-labs/strive2-ui';
import { $API, useUserProfile } from '@strive/api';

import { WearableErrors } from '../progress/landing/wearable-errors';
import { AuthorizeWearable } from './authorize-wearable';

export default function UserWearablePage() {
  const userQuery = useUserProfile();
  const launchpadQuery = $API.useGetLaunchpad();
  const homepageQuery = $API.useGetHomepage();

  return (
    <PageStack w="full" align="stretch">
      <WaitForQueries queries={[userQuery, launchpadQuery, homepageQuery]}>
        {(user, launchpad, homepage) => {
          const wearableInfo = user.profile.wearable[0];
          const learningState = homepage.learning_state;
          const wearableReceived =
            launchpad.wearable?.status === 'delivered' ||
            launchpad.wearable?.status === 'complete';

          if (learningState === 'launchpad' && !wearableReceived) {
            return (
              <VStack spacing={6} textAlign={'center'}>
                <WearableErrors
                  title={'Get Ready to Connect!'}
                  message={
                    <Text
                      textStyle={'copy_small'}
                      color={'neutral.300'}
                      lineHeight={'taller'}
                    >
                      {' '}
                      Complete the steps in your wearable setup to ensure the
                      timely arrival of your wearable!
                    </Text>
                  }
                />
                <Text
                  textStyle={'copy_small'}
                  color={'neutral.400'}
                  lineHeight={'taller'}
                >
                  Your device page will be available when you pair your device
                  and authorize the data with Strive.
                </Text>
              </VStack>
            );
          } else {
            return (
              <Fragment>
                <WearableStatus />
                <Box
                  mt={3}
                  mb={6}
                  h={'2px'}
                  w={'full'}
                  bg={'neutral.900'}
                ></Box>

                {match(wearableInfo)
                  .with({ status: 'valid' }, (wearable) => (
                    <Flex
                      direction={'column'}
                      gap="2"
                      alignItems={'center'}
                      w={'full'}
                    >
                      {wearable?.errors && (
                        <>
                          <WearableErrors
                            title={wearable?.errors?.title}
                            message={
                              <Text
                                textStyle={'copy_small'}
                                lineHeight={'tall'}
                                textAlign={'center'}
                              >
                                {wearable?.errors?.message}
                              </Text>
                            }
                            action={wearable?.provider}
                          />
                        </>
                      )}
                    </Flex>
                  ))
                  .with(
                    {
                      // status: 'invalid' || 'revoked' || 'expired',
                      url: P.string,
                    },
                    (wearable) => (
                      <>
                        <AuthorizeWearable
                          url={wearable.url}
                          provider={wearable.provider}
                          status={wearable.status}
                        />
                        <Text
                          textStyle={'copy_small'}
                          mt={6}
                          textAlign={'center'}
                          display={'flex'}
                          flexDirection={'column'}
                          lineHeight={'tall'}
                        >
                          <span>Encountering an issue? </span>
                          <span>
                            Please reach out to{' '}
                            <Link
                              variant={'underline'}
                              as={NextLink}
                              href="/support"
                              color={'primary.700'}
                            >
                              Strive Support
                            </Link>{' '}
                            for assistance.
                          </span>
                        </Text>
                      </>
                    ),
                  )
                  .otherwise(() => (
                    <AlertBox
                      status="error"
                      title="Data Unavailable"
                      description="We're not able to download your wearable data right now. Please contact support if you think this is an error."
                    />
                  ))}
              </Fragment>
            );
          }
        }}
      </WaitForQueries>
    </PageStack>
  );
}

function WearableStatus() {
  const biometricsQuery = $API.useGetBiometrics();

  return (
    <WaitForQuery query={biometricsQuery} loading={null}>
      {(biometrics) => {
        const wearable = biometrics.wearable?.[0];

        if (!wearable) {
          return null;
        }

        return (
          <Flex
            mt={'6'}
            gap="3"
            py="2"
            justify="space-between"
            color="icon.primary"
            align="center"
            px={2}
            w={'full'}
            bg={'teal.300'}
          >
            <Flex gap="3" py="2">
              {wearable.provider == 'whoop' ? (
                <Icon as={WhoopLogoData} color="white" boxSize={6} />
              ) : wearable.provider == 'oura' ? (
                <Icon as={OuraLogoData} color="white" boxSize={6} />
              ) : (
                <Icon as={PartnerDeviceIcon} color="white" boxSize={6} />
              )}
              <Text
                textStyle={'copy_bold'}
                color="white"
                textTransform="capitalize"
              >
                {wearable.provider}
              </Text>
            </Flex>

            <Flex direction="column">
              <Flex ml="auto" direction="row" alignItems="center" gap="2">
                <Circle
                  size={2}
                  bg={
                    wearable.status !== 'valid'
                      ? 'negative.100'
                      : wearable.is_synced_today
                      ? 'positive.100'
                      : 'warning.300'
                  }
                />
                <Text
                  textStyle={'copy_bold'}
                  color={
                    wearable.status !== 'valid'
                      ? 'negative.100'
                      : wearable.is_synced_today
                      ? 'positive.100'
                      : 'warning.300'
                  }
                >
                  {wearable.status !== 'valid'
                    ? 'Not Connected'
                    : wearable.is_synced_today
                    ? 'Synced Today'
                    : 'Not Synced Today'}
                </Text>
              </Flex>

              <Text
                textStyle={'copy_extra_small'}
                color="neutral.300"
                textAlign="right"
              >
                {wearable.last_synced_at
                  ? ` Updated ${formatRelativeTime(wearable.last_synced_at)}`
                  : 'No Update History'}
              </Text>
            </Flex>
          </Flex>
        );
      }}
    </WaitForQuery>
  );
}
