import { useMemo } from 'react';
import { Button, Flex, ListItem, OrderedList, Text } from '@chakra-ui/react';
import { match, P } from 'ts-pattern';
import { waitFor } from 'xstate5';

import { BackLink, LottieFile, useResponsive } from '@arena-labs/strive2-ui';
import { openSettingsPage } from '@strive/notifications';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

import { useInitialDevicePairingStats, useSetupActor } from './hooks';
import { SetupBranding } from './setup-branding';

export function SetupCharging() {
  const strivewareActor = StrivewareContext.useActorRef();
  const state = StrivewareContext.useSelector(
    StrivewareSelector.bluetoothState,
  );
  const [firstPairingStats, setPairingStats] = useInitialDevicePairingStats();
  const rs = useResponsive();
  const setupActor = useSetupActor();

  const onStartScanning = async () => {
    if (firstPairingStats.data) {
      const now = new Date();
      setPairingStats.mutate({
        ...firstPairingStats.data,
        startedPairingTime: now,
      });
    }
    if (state === 'permission.prompt') {
      strivewareActor.send({ type: 'Request Permissions' });
      const snapshot = await waitFor(
        strivewareActor,
        (state) =>
          StrivewareSelector.bluetoothState(state) !== 'permission.prompt',
      );
      if (snapshot.matches('Bluetooth Ready')) {
        setupActor.send({ type: 'Next' });
      }
    } else if (state === 'bluetooth.ready') {
      setupActor.send({ type: 'Next' });
    }
  };

  const animation = useMemo(() => import('./charge-device.lottie.json'), []);

  return (
    <Flex direction="column" px="6" py={4} gap="6" h="full">
      <Flex gap="4" alignItems="center">
        <BackLink
          href="/"
          color="neutral.white"
          onClick={(event) => {
            event.preventDefault();
            setupActor.send({ type: 'Back' });
          }}
        />
        <SetupBranding />
      </Flex>

      {match(state)
        .with(P.union('permission.prompt', 'bluetooth.ready'), () => (
          <>
            <Text
              textStyle={rs({ base: 'h2', xs: 'h4' })}
              as="h1"
              color="neutral.white"
            >
              Charge your StriveWare before initial pairing.
            </Text>
            <Text textStyle="copy_small" color="neutral.white">
              Keep Bluetooth on for real-time insights with StriveWare.
            </Text>
            <LottieFile
              as={animation}
              boxSize={rs({ base: '246px', xs: '180px' })}
              my="auto"
              alignSelf="center"
            />
            <Button onClick={onStartScanning} mt="auto" variant="secondary">
              Start
            </Button>
          </>
        ))
        .with('permission.denied', () => (
          <>
            <Text
              textStyle={rs({ base: 'h2', xs: 'h4' })}
              as="h1"
              color="neutral.white"
            >
              Enable Bluetooth permissions
            </Text>
            <Text textStyle="copy_bold" color="neutral.white">
              To complete the pairing process and access data, please allow
              Bluetooth permission in your phone settings.
            </Text>
            <Text textStyle="copy_extra_small" color="neutral.white">
              Follow these steps to enable Bluetooth permission for Arena
              Strive:
            </Text>
            <OrderedList
              ml="4"
              spacing="2"
              textStyle="copy_small"
              color="neutral.white"
            >
              <ListItem>Go to Settings on your phone.</ListItem>
              <ListItem>Select Arena Strive from the list of apps.</ListItem>
              <ListItem>
                Allow Arena Strive to access Bluetooth by toggling the switch to
                ON.
              </ListItem>
            </OrderedList>
            <Button
              variant={'primary'}
              onClick={() => openSettingsPage()}
              mt="auto"
            >
              Open Settings
            </Button>
          </>
        ))
        .with('bluetooth.off', () => (
          <>
            <Text
              textStyle={rs({ base: 'h2', xs: 'h4' })}
              as="h1"
              color="neutral.white"
            >
              Enable Bluetooth
            </Text>
            <Text textStyle="copy_small" color="neutral.white">
              To complete the pairing process and access data, please enable
              Bluetooth in your phone settings.
            </Text>
          </>
        ))
        .exhaustive()}
    </Flex>
  );
}
