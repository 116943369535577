import { useRouter } from 'next/router';
import { Box, Flex } from '@chakra-ui/react';
import { match } from 'ts-pattern';

import { StrivewareContext } from '@strive/wearable';

import { StrivewareSetup } from './striveware/setup/striveware-setup';
import { StrivewareDevice } from './striveware/striveware-device';
import { SlideComponentOptions } from './wearable-hub';

export function StrivewareWearable({
  setSlide,
}: {
  setSlide: (slide: SlideComponentOptions) => void;
}) {
  const router = useRouter();
  const view = StrivewareContext.useSelector((state) => {
    console.log('state wearablepage', state.context.pairedDevice);
    return state.context.pairedDevice ? 'device-details' : 'setup';
  });

  const handleNav = (path: string) => {
    router.push(path);
  };
  return (
    <Flex direction={'column'} height={'auto'} minHeight={0} width="100%">
      {match(view)
        .with('setup', () => (
          <Flex
            direction={'column'}
            pb={0}
            width="100%"
            height={'auto'}
            minHeight={0}
          >
            <StrivewareSetup
              onCancel={() => {
                handleNav('/');
              }}
            />
            <Box
              h={'10px'}
              w={'120px'}
              bg={'neutral.200'}
              mx={'auto'}
              mb={'2'}
              rounded={'full'}
            />
          </Flex>
        ))
        .with('device-details', () => (
          <Flex width="100%" height={'auto'} minHeight={0}>
            <StrivewareDevice setSlide={setSlide} />
          </Flex>
        ))
        .exhaustive()}
    </Flex>
  );
}
