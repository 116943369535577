import { chakra, Flex } from '@chakra-ui/react';

import { WearableTrackingTag } from '@arena-labs/analytics';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

// import { StrivewareHeader } from '../../../components/wearable/striveware/striveware-header';
// import { StrivewareLayout } from '../../../components/wearable/striveware/striveware-layout';
function StrivewareDeviceInfo() {
  const device = StrivewareContext.useSelector(StrivewareSelector.userDevice);

  return (
    <Flex
      direction="column"
      gap="4"
      w="full"
      bg="white"
      color="black"
      borderRadius="base"
      p="4"
    >
      <chakra.dl display="flex" flexDirection="column" gap="4">
        <Flex gap="2">
          <chakra.dt textStyle="h5">Model:</chakra.dt>
          <chakra.dd>
            {device?.version ? `v${device.version}` : 'Unknown'}
          </chakra.dd>
        </Flex>
        <Flex gap="2">
          <chakra.dt textStyle="h5">MAC Address:</chakra.dt>
          <chakra.dd>{device?.mac ?? 'Unknown'}</chakra.dd>
        </Flex>
      </chakra.dl>
    </Flex>
  );
}

StrivewareDeviceInfo.analyticsTags = [
  WearableTrackingTag.WearablePage,
  WearableTrackingTag.StriveWareWearable,
];
export default StrivewareDeviceInfo;
