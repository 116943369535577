import Lottie from 'lottie-react';

import animationLoading from './aci-loading.json';

export const LottieGif = ({
  animationAlias,
}: {
  animationAlias: 'animationLoading';
}) => {
  const lottieData =
    animationAlias === 'animationLoading' ? animationLoading : null;

  return <Lottie loop animationData={lottieData} />;
};
