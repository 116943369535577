import { Flex, Text } from '@chakra-ui/react';

// import { StrivewareHeader } from '../../../components/wearable/striveware/striveware-header';
// import { StrivewareLayout } from '../../../components/wearable/striveware/striveware-layout';
import { WearableTrackingTag } from '@arena-labs/analytics';
import { useResponsive } from '@arena-labs/strive2-ui';

export function StrivewareWhenToWearPage() {
  const rs = useResponsive();

  return (
    <Flex
      direction={'column'}
      pb={2}
      gap={rs({ base: 8, xs: 4 })}
      h={'100%'}
      w={'full'}
      color={'neutral.white'}
    >
      <Text textStyle={rs({ base: 'h3', xs: 'h2' })}>
        Wear during Sleep Hours
      </Text>
      <Text textStyle={'copy'}>
        While it may seem counterintuitive, the most valueable data we can gain
        about your day occurs while you&apos;re asleep!
      </Text>
      <Text textStyle="copy">
        If you only wear your StriveWare during the hours your are home, your
        coach will receive the info they need to provide you with guidance.
      </Text>
      <Text textStyle="copy">
        Wearing the ring all day is a great reminder to engage in the Tools
        you&apos;re learning, so don&apos;t hesitate to keep it on if you&apos;d
        prefer!
      </Text>
    </Flex>
  );
}

StrivewareWhenToWearPage.analyticsTags = [
  WearableTrackingTag.WearablePage,
  WearableTrackingTag.StriveWareWearable,
];
