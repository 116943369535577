import React from 'react';
import { Button, Flex } from '@chakra-ui/react';

import { useResponsive } from '@arena-labs/strive2-ui';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

export function DeviceActions() {
  const rs = useResponsive();
  const strivewareActor = StrivewareContext.useActorRef();
  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );
  const actions = StrivewareContext.useSelector((state) => ({
    retry: state.can({ type: 'Retry' })
      ? () => strivewareActor.send({ type: 'Retry' })
      : null,
    sync: state.can({ type: 'Sync' })
      ? () => strivewareActor.send({ type: 'Sync' })
      : null,
  }));

  return (
    <Flex direction="column" gap="4" align="stretch" gridArea="actions">
      <Button
        onClick={() => actions.sync?.()}
        variant="black"
        isLoading={deviceState === 'syncing'}
        loadingText="Syncing..."
        disabled={deviceState === 'syncing' || !actions.sync}
        size={rs({ base: 'lg', xs: 'sm' })}
        textStyle={rs({ base: 'h3', xs: 'h5' })}
      >
        Sync Data
      </Button>
      {actions.retry && (
        <Button
          onClick={actions.retry}
          variant="black"
          size={rs({ base: 'lg', xs: 'sm' })}
        >
          Retry connection
        </Button>
      )}
    </Flex>
  );
}
