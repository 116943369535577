import { Flex, ListItem, OrderedList, Text } from '@chakra-ui/react';

import { WearableTrackingTag } from '@arena-labs/analytics';
import { useResponsive } from '@arena-labs/strive2-ui';

// import { StrivewareHeader } from './striveware/striveware-header';
// import { StrivewareLayout } from './striveware/striveware-layout';
export function StrivewareTroubleshooting() {
  const rs = useResponsive();
  return (
    <Flex
      direction={'column'}
      gap={rs({ base: '4', xs: '3' })}
      color={'neutral.white'}
    >
      <Flex direction="column" gap="4">
        <Text as="h2" textStyle={rs({ base: 'h4', xs: 'copy_bold' })}>
          If the wearable doesn&apos;t pair with your smartphone:
        </Text>
        <OrderedList ml="4" spacing="2" textStyle="copy_small">
          <ListItem>Make sure your wearable is charged</ListItem>
          <ListItem>Restart the App and try to repair the wearable.</ListItem>
        </OrderedList>
      </Flex>

      <Flex direction="column" gap={rs({ base: '4', xs: '3' })}>
        <Text textStyle={rs({ base: 'h5', xs: 'copy_bold' })}>
          If the wearable battery is draining too quickly:
        </Text>
        <OrderedList ml="4" spacing="2" textStyle="copy_small">
          <ListItem>
            Calibrate your battery by charging it for at least three hours.
          </ListItem>
          <ListItem>
            Start using your wearable again as you normally would.
          </ListItem>
          <ListItem>Contact our support team if the problem persists.</ListItem>
        </OrderedList>
      </Flex>

      <Text textStyle="copy_small">
        If the wearable causes an allergic reaction, please stop using the
        device right away. In the rare event of an anaphylactic reaction, seek
        medical attention immediately.
      </Text>
      <Text textStyle="copy_small">
        If the wearable isn&apos;t measuring any data please contact our support
        team at <a href="mailto:help@arenalabs.co">help@arenalabs.co</a>.
      </Text>
      <Text textStyle="copy_small" mb={'auto'}>
        If the issue isn&apos;t mentioned in these tips, please contact our
        support team and report the issue in detail.
      </Text>
    </Flex>
  );
}

StrivewareTroubleshooting.analyticsTags = [
  WearableTrackingTag.WearablePage,
  WearableTrackingTag.StriveWareWearable,
];
