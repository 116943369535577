import 'swiper/css';
import 'swiper/css/a11y';

import {
  Button,
  Center,
  Flex,
  Image,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import { A11y } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { WaitForQuery } from '@arena-labs/strive2-ui';

import { PracticeSetCard } from './practice-set-card';
import { RecommendedFlag } from './recommended-flag';
import { useAvailablePracticeSets } from './use-available-practice-sets';

export type PracticeSetCarouselProps = {
  count?: number;
  onViewAll?: () => void;
};

export function PracticeSetCarousel({
  count = 3,
  onViewAll,
}: PracticeSetCarouselProps) {
  const practiceSetQuery = useAvailablePracticeSets(count);

  return (
    <WaitForQuery query={practiceSetQuery}>
      {([pillars, totalPillars]) => {
        if (pillars.length === 0) {
          return (
            <Flex h="full" pb={4}>
              <PracticeSetCard
                type="explorations"
                isActive
                completedFoundations
                tag={
                  <Flex gap="2" align="center">
                    <Image
                      src="/images/learning-journey/finish-flag-icon.webp"
                      alt=""
                      boxSize={12}
                      marginRight="-6"
                      zIndex="1"
                    />
                    <Text
                      fontFamily="secondary"
                      textStyle="h3"
                      bg="neutral.900"
                      py="1"
                      px="3"
                      borderRadius="base"
                    >
                      Foundations Complete!
                    </Text>
                  </Flex>
                }
              />
            </Flex>
          );
        }
        return (
          <Flex h="full" mx="-6" px={6} overflow="visible">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={12}
              style={{ width: '100%', height: '100%', overflow: 'visible' }}
              modules={[A11y]}
              role="region"
              a11y={{ containerMessage: 'Available choices' }}
            >
              <VisuallyHidden slot="container-start">
                <A11ySwiperNavigation />
              </VisuallyHidden>
              <VisuallyHidden slot="container-end">
                <A11ySwiperNavigation />
              </VisuallyHidden>

              {pillars.map((pillar, index) => (
                <SwiperSlide key={pillar.slug} style={{ width: '100%' }}>
                  <PracticeSetCard
                    type="pillar"
                    pillar={pillar}
                    tag={index === 0 && <RecommendedFlag />}
                  />
                </SwiperSlide>
              ))}
              {pillars.length < count && (
                <SwiperSlide>
                  <PracticeSetCard type="explorations" />
                </SwiperSlide>
              )}

              {onViewAll && totalPillars > 3 && (
                <SwiperSlide style={{ width: 'auto' }}>
                  <ViewAllButton onClick={onViewAll} />
                </SwiperSlide>
              )}
            </Swiper>
          </Flex>
        );
      }}
    </WaitForQuery>
  );
}

function A11ySwiperNavigation() {
  const swiper = useSwiper();
  return (
    <Flex justify="space-between">
      <Button onClick={() => swiper.slidePrev()}>Previous</Button>
      <Button onClick={() => swiper.slideNext()}>Next</Button>
    </Flex>
  );
}

function ViewAllButton({ onClick }: { onClick: () => void }) {
  return (
    <Center w={'auto'} h={'full'}>
      <Button
        variant="underline-link"
        color={'neutral.600'}
        onClick={() => onClick()}
      >
        View All
      </Button>
    </Center>
  );
}
