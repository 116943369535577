import { useEffect, useState } from 'react';
import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react';

import { DataTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { PillarPractice } from '@arena-labs/shared-models';
import {
  PracticeIcon,
  useFadeEntryFadeExit,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

export function PracticeReview({
  onReviewComplete,
  submissionError,
  logPracticeCard,
}: {
  submissionError: boolean;
  onReviewComplete: () => void;
  logPracticeCard: (practice: PillarPractice) => React.ReactNode;
}) {
  const analytics = useAnalytics();
  const pillarQuery = $API.useGetPillars(
    {},
    {
      select: (pillars) => {
        const allPractices = pillars.flatMap((pillar) => pillar.practices);
        const unlockedPractices = allPractices.filter((p) => p.log_count > 0);
        const completedPractices = allPractices.filter(
          (p) => p.completed_today,
        );
        return {
          unlocked: unlockedPractices,
          completed: completedPractices,
        };
      },
    },
  );
  const { scope, animate } = useFadeEntryFadeExit();

  const [logMore, setLogMore] = useState(false);

  useEffect(() => {
    animate(
      '#logMoreButton',
      { opacity: 1, y: ['200px', '0px'] },
      { duration: 0.33, delay: 0.5 },
    );
  }, [animate]);

  return (
    <Flex ref={scope} flexGrow={1}>
      <Flex direction={'column'} h={'full'}>
        <Text textStyle={'copy_bold'} color={'logo'} mt={10} mb={4}>
          3 of 3
        </Text>

        <Text textStyle={'h5'} mb={10}>
          Which Strive Tools did you use to move through those challenges?
        </Text>

        <Flex
          grow="1"
          shrink="1"
          basis="100px"
          direction={'column'}
          gap={6}
          w={'full'}
          bg={'neutral.white'}
          shadow={'4dp'}
          p={4}
          mb={'auto'}
          rounded={'card'}
          overflow={'auto'}
        >
          <WaitForQuery query={pillarQuery}>
            {({ completed, unlocked }) => {
              if (logMore) {
                return (
                  <Flex direction={'column'} gap={4}>
                    {unlocked.map((practice) => logPracticeCard(practice))}
                  </Flex>
                );
              } else if (!logMore) {
                return completed.map((practiceComplete) => (
                  <Grid
                    templateColumns={'40px 1fr'}
                    columnGap={4}
                    alignItems={'center'}
                    key={practiceComplete.slug}
                  >
                    <PracticeIcon slug={practiceComplete.slug} />

                    <Text textStyle={'copy_bold'} key={practiceComplete.slug}>
                      {practiceComplete.short_title}
                    </Text>
                  </Grid>
                ));
              } else {
                return (
                  <Text
                    textStyle={'copy_small'}
                    mx={'auto'}
                    textAlign={'center'}
                  >
                    {logMore
                      ? 'There are no other practices to record right now.'
                      : 'There were no practices recorded today.'}
                  </Text>
                );
              }
            }}
          </WaitForQuery>
        </Flex>

        <Button
          variant={'underline-link'}
          color={'neutral.800'}
          mt={'auto'}
          my="4"
          onClick={() => setLogMore(!logMore)}
          opacity={0}
          id={'logMoreButton'}
        >
          {logMore ? 'Return' : 'Log More'}
        </Button>
        {submissionError && (
          <Box
            px={3}
            mx={'auto'}
            my={3}
            rounded={'sm'}
            transition={'all 0.35s ease'}
            w="auto"
            bg={'negative.200'}
            textAlign={'center'}
            color={'neutral.300'}
          >
            An error occured, please submit again!
          </Box>
        )}
        <Button
          variant={'primary'}
          w={'full'}
          onClick={() => {
            analytics.logEvent(DataTrackingEvent.ACISubmitted);
            onReviewComplete();
          }}
        >
          {' '}
          Generate Insights
        </Button>
      </Flex>
    </Flex>
  );
}
