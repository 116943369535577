import NextLink from 'next/link';
import { Button, Center, Flex, Icon, Text } from '@chakra-ui/react';

import {
  LightbulbIcon,
  Markdown,
  SVGIconComponentType,
} from '@arena-labs/strive2-ui';
import { WearableProviders } from '@strive/api';

import { openApp } from '../../../lib/open-app';

export function WearableErrors({
  title,
  message,
  action,
  wearable,
  largeIcon,
}: {
  title: string;
  message: string | React.ReactNode;
  action?: string | null;
  wearable?: WearableProviders;
  largeIcon?: SVGIconComponentType;
}) {
  return (
    <Flex
      direction={'column'}
      bg="neutral.50"
      color="neutral.800"
      p={4}
      rounded="card"
      border={'1px solid'}
      borderColor={'warning.300'}
    >
      {largeIcon && (
        <Icon
          color={'netral.white'}
          as={largeIcon}
          boxSize={9}
          mb={4}
          mx={'auto'}
        />
      )}
      <Center gap="3" mb="3">
        <Icon as={LightbulbIcon} color={'warning.300'} boxSize={6} />
        <Text textStyle={'copy_bold'}>{title}</Text>
      </Center>
      {typeof message === 'string' && (
        <Markdown
          textStyle="copy_small"
          color="neutral.600"
          lineHeight={'tall'}
          textAlign={'center'}
          options={{
            components: {
              h1: (props) => <Text {...props} textStyle="h1" />,
              h2: (props) => <Text {...props} textStyle="h2" />,
              h3: (props) => <Text {...props} textStyle="h3" />,
            },
          }}
        >
          {message}
        </Markdown>
      )}
      {typeof message === 'object' && message}
      {action && wearable !== 'striveware' && (
        <Button
          variant="primary"
          size="xs"
          py="3"
          my="3"
          textTransform="capitalize"
          onClick={() => openApp(action)}
        >
          Open {action} App
        </Button>
      )}
      {action && wearable === 'striveware' && (
        <Button
          variant="primary"
          size="xs"
          py="3"
          my="3"
          textTransform="capitalize"
          as={NextLink}
          href="/wearable/striveware"
        >
          View Striveware
        </Button>
      )}
    </Flex>
  );
}
