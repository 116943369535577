import React from 'react';
import { Flex, Grid, Image, Progress, Text } from '@chakra-ui/react';

import { useResponsive, WaitForQuery } from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';
import {
  StrivewareContext,
  StrivewareSelector,
  useStrivewareBattery,
} from '@strive/wearable';

import { StrivewareBattery } from './striveware-battery';

export function DeviceDetails() {
  const userQuery = useUserProfile();
  const batteryQuery = useStrivewareBattery();
  const device = StrivewareContext.useSelector(StrivewareSelector.userDevice);
  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );
  const deviceStatus = StrivewareContext.useSelector(
    StrivewareSelector.deviceStatusText,
  );
  const syncProgress = StrivewareContext.useSelector(
    (state) => state.context.syncProgress,
  );

  const rs = useResponsive();

  return (
    <WaitForQuery query={userQuery} loading={null}>
      {(user) => {
        const deviceName =
          user.profile.wearable[0]?.display_name || 'My StriveWare';
        return (
          <Grid
            gridTemplate={`"ring details" auto / auto 1fr`}
            w="full"
            gap="4"
          >
            <Image
              src={'/images/striveware/black-ring-grey-light.webp'}
              w={rs({ base: 32, xs: 16 })}
              alt=""
              gridArea="ring"
            />
            <Flex direction="column" gap="2" gridArea="details" mt="2">
              <Text textStyle={rs({ base: 'h3', xs: 'h5' })}>{deviceName}</Text>

              <Flex direction={rs({ base: 'column', xs: 'row' })} gap={2}>
                <Text textStyle="copy">{device?.name}</Text>
                <Text
                  textStyle="copy"
                  color={
                    deviceStatus === 'Not Connected' ||
                    deviceStatus.endsWith('failed')
                      ? 'negative.200'
                      : 'black'
                  }
                >
                  {deviceStatus}
                </Text>
              </Flex>
              <WaitForQuery query={batteryQuery} loading={null} error={null}>
                {({ batteryLevel }) => (
                  <Text
                    textStyle="copy_small"
                    display="inline-flex"
                    alignItems="center"
                    gap="1"
                  >
                    <StrivewareBattery level={batteryLevel} size={3.5} />
                    {batteryLevel}%
                  </Text>
                )}
              </WaitForQuery>
              <Progress
                visibility={deviceState === 'syncing' ? 'visible' : 'hidden'}
                size="xs"
                value={syncProgress}
                isIndeterminate={syncProgress === 0}
              />
            </Flex>
          </Grid>
        );
      }}
    </WaitForQuery>
  );
}
