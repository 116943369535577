import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useAnimate } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useGradient } from '@arena-labs/strive2-ui';
import { IfWearable, StrivewareContext } from '@strive/wearable';

import StrivewareDeviceInfo from './device-info';
import UserWearablePage from './partner';
import { StrivewareWearable } from './striveware';
import { StrivewareSubmenu } from './striveware-submenu';
import { StrivewareTroubleshooting } from './troubleshooting';
import { useWearableHub } from './use-wearable-hub';
import { StrivewareWhenToWearPage } from './when-to-wear';

export type SlideComponentOptions =
  | 'whenToWear'
  | 'deviceInfo'
  | 'troubleShooting'
  | '';

export function WearableHub({ children }: { children: React.ReactNode }) {
  const bgGradient = useGradient('StriveWare.bgDarkGradient');
  const { isOpen, onClose } = useWearableHub();
  const [scope, animate] = useAnimate();
  const [slide, setSlide] = useState<SlideComponentOptions | null>(null);
  const [view, setView] = useState<'device-details' | 'setup' | null>(null);
  const [slideHeight, setSlideHeight] = useState<number>(0);
  const firstSlideRef = useRef<HTMLDivElement>(null);

  const slideComponents: Record<SlideComponentOptions, React.ReactNode> = {
    whenToWear: <StrivewareWhenToWearPage />,
    troubleShooting: <StrivewareTroubleshooting />,
    deviceInfo: <StrivewareDeviceInfo />,
    '': null,
  };
  const handleSettingSlide = (slide: SlideComponentOptions) => {
    setSlide(slide);
  };
  const handleTouchStart = (event: TouchEvent) => {
    const touchStartY = event.touches[0]?.clientY;
    let localTranslateY = 0;

    const handleTouchMove = (event: TouchEvent) => {
      const touchEndY = event.touches[0]?.clientY;
      const deltaY = touchStartY && touchEndY ? touchStartY - touchEndY : 0;

      if (scope.current) {
        if (deltaY > 0 && deltaY < 200) {
          animate(scope.current, { translateY: -deltaY });
        }

        if (deltaY >= 200) {
          animate(scope.current, { translateY: -1000 }, { duration: 0.5 })
            .then(() => {
              localTranslateY = deltaY;
            })
            .then(() => {
              window.removeEventListener('touchmove', handleTouchMove);
            });
        }
      }
    };

    window.addEventListener('touchmove', handleTouchMove);
    const handleTouchEnd = () => {
      if (localTranslateY < 200) {
        if (scope.current) {
          animate(scope.current, { translateY: 0 });
        }
      } else {
        animate(scope.current, { translateY: -1000 }, { duration: 0.5 }).then(
          () => {
            onClose();
          },
        );
      }
      window.removeEventListener('touchmove', handleTouchMove);
    };
    window.addEventListener('touchend', handleTouchEnd, { once: true });
  };

  const DeviceContent = () => {
    const deviceView = StrivewareContext.useSelector((state) => {
      return state.context.pairedDevice ? 'device-details' : 'setup';
    });

    useEffect(() => {
      setView(deviceView);
    }, [deviceView]);

    return null;
  };

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart);
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!firstSlideRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setSlideHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(firstSlideRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Drawer isOpen={isOpen} placement="top" onClose={onClose}>
        <DrawerContent
          padding={0}
          roundedBottom={'card'}
          paddingTop={'env(safe-area-inset-top)'}
          boxShadow={'none'}
          overflow="hidden"
        >
          <Box ref={scope}>
            <Box
              h="auto"
              roundedBottom={'card'}
              marginBottom="-1px"
              transition="height 0.3s ease"
            >
              <IfWearable mode="striveware">
                <DeviceContent />
                <DrawerOverlay
                  zIndex={-1}
                  height={'calc(100% + 60px)'}
                  marginTop={`-60px`}
                  roundedBottom={'card'}
                >
                  <Box
                    h={'100%'}
                    bg={view === 'setup' ? bgGradient : 'neutral.white'}
                    roundedBottom={'card'}
                  />
                </DrawerOverlay>
              </IfWearable>
              <DrawerBody
                padding={0}
                roundedBottom={'card'}
                h={'100%'}
                overflow="hidden"
              >
                <IfWearable mode="striveware">
                  <Swiper
                    style={{ height: '100%', minHeight: 'auto' }}
                    allowSlideNext={slide !== null}
                  >
                    <SwiperSlide>
                      <Box ref={firstSlideRef} transition="height 0.3s ease">
                        <StrivewareWearable
                          setSlide={(slide) =>
                            handleSettingSlide(slide as SlideComponentOptions)
                          }
                        />
                      </Box>
                    </SwiperSlide>
                    <SwiperSlide
                      style={{
                        background: bgGradient,
                        height: slideHeight ? `${slideHeight}px` : 'auto',
                        transition: 'height 0.3s ease',
                      }}
                    >
                      <StrivewareSubmenu
                        onBack={() => setSlide(null)}
                        setSlide={setSlide}
                      >
                        {slideComponents[slide ?? '']}
                      </StrivewareSubmenu>
                    </SwiperSlide>
                  </Swiper>
                </IfWearable>

                <IfWearable mode="partner">
                  <Box h={'100%'}>
                    <Swiper style={{ height: '100%' }}>
                      <SwiperSlide>
                        <Box h={'100%'} />
                      </SwiperSlide>
                      <SwiperSlide>
                        <UserWearablePage />
                      </SwiperSlide>
                    </Swiper>
                  </Box>
                </IfWearable>
              </DrawerBody>
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>
      {children}
    </>
  );
}
