import React from 'react';
import { Capacitor } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';
import { SplashScreen } from '@capacitor/splash-screen';
import { Box, Button, Divider, Flex } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import {
  ChevronRightIcon,
  Icon,
  toast,
  useGradient,
  useResponsive,
} from '@arena-labs/strive2-ui';
import { JStyle } from '@strive/jstyle';
import { StrivewareContext } from '@strive/wearable';

import { SlideComponentOptions } from '../wearable-hub';
import { DeviceActions } from './striveware-device-actions';
import { DeviceAlerts } from './striveware-device-alerts';
import { DeviceDetails } from './striveware-device-details';

export function StrivewareDevice({
  setSlide,
}: {
  setSlide: (slide: SlideComponentOptions) => void;
}) {
  const swiper = useSwiper();
  const bgGradient = useGradient('StriveWare.bgDarkGradient');
  const rs = useResponsive();
  const strivewareActor = StrivewareContext.useActorRef();
  const handleDeviceDelete = () =>
    promptDeleteDevice(() => strivewareActor.send({ type: 'Unpair Device' }));

  const handleRestart = () =>
    promptAppRestart(async () => {
      if (Capacitor.isNativePlatform()) {
        await SplashScreen.show();
      }
      window.location.reload();
    });

  return (
    <Flex
      direction="column"
      gap="4"
      w="full"
      h="100%"
      overflow="auto"
      bg={bgGradient}
    >
      <Flex
        direction="column"
        layerStyle="4dp"
        position="sticky"
        zIndex="1"
        top="0"
        gap={rs({ base: 3, xs: 2 })}
        bg="neutral.white"
        color="black"
        w="full"
        borderBottomRadius="xl"
        px="6"
        pb="4"
        pt={'4'}
      >
        <DeviceDetails />
        <DeviceAlerts />
        <DeviceActions />
      </Flex>

      <Flex
        zIndex={1}
        direction="column"
        gap={rs({ base: 3, xs: 2 })}
        w="full"
        px="6"
        borderColor="white"
        color="neutral.white"
      >
        <Button
          variant={'inline'}
          onClick={() => {
            setSlide('whenToWear');
            setTimeout(() => {
              swiper.slideTo(1);
            }, 100);
          }}
          textStyle={rs({ base: 'copy', xs: 'copy_small' })}
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
        >
          When to wear
          <Icon as={ChevronRightIcon} size="3" />
        </Button>
        <Divider />
        <Button
          variant={'inline'}
          onClick={() => {
            setSlide('troubleShooting');
            setTimeout(() => {
              swiper.slideTo(1);
            }, 100);
          }}
          textStyle={rs({ base: 'copy', xs: 'copy_small' })}
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Troubleshoot
          <Icon as={ChevronRightIcon} size="3" />
        </Button>
        <Divider />
        <Button
          variant={'inline'}
          onClick={() => {
            setSlide('deviceInfo');
            setTimeout(() => {
              swiper.slideTo(1);
            }, 100);
          }}
          textStyle={rs({ base: 'copy', xs: 'copy_small' })}
          display="inline-flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Device Info
          <Icon as={ChevronRightIcon} size="3" />
        </Button>
      </Flex>

      <Flex
        direction="column"
        gap={rs({ base: 3, xs: 2 })}
        w="full"
        px="6"
        pb="4"
        pt={rs({ base: 4, xs: 0 })}
        borderColor="white"
        color="neutral.white"
      >
        <Button
          variant="inline"
          justifyContent="left"
          textStyle={rs({ base: 'p1_semibold', xs: 'p2_semibold' })}
          onClick={handleDeviceReset}
        >
          Reset my Device
        </Button>
        <Divider />
        <Button
          variant="inline"
          justifyContent="left"
          textStyle={rs({ base: 'copy', xs: 'copy_small' })}
          onClick={handleDeviceDelete}
        >
          Unpair my Device
        </Button>
        <Divider />
        <Button
          variant="inline"
          justifyContent="left"
          textStyle={rs({ base: 'copy', xs: 'copy_small' })}
          onClick={handleRestart}
        >
          Restart
        </Button>
      </Flex>
      <Box
        w={'120px'}
        h={'10px'}
        bg={'neutral.200'}
        mx={'auto'}
        rounded={'full'}
        mb={'2'}
        mt={'auto'}
      />
    </Flex>
  );
}

async function handleDeviceReset() {
  const result = await Dialog.confirm({
    title: 'Reset Device',
    message: `Are you sure you want to reset your device?\n
      This will delete all data on your device and reset it to factory settings.`,
    okButtonTitle: 'Reset',
    cancelButtonTitle: 'Cancel',
  });
  if (result.value) {
    JStyle.factoryReset()
      .then(() => {
        toast({
          status: 'success',
          description: 'Device reset successfully',
        });
      })
      .catch(() => {
        toast({
          status: 'error',
          description: 'There was an error resetting your device',
        });
      });
  }
}

async function promptDeleteDevice(onConfirm: () => unknown) {
  const result = await Dialog.confirm({
    title: 'Unpair Device',
    message: `Are you sure you want to unpair your device?\n\nThis will delete the device from your account.`,
    okButtonTitle: 'Unpair',
    cancelButtonTitle: 'Cancel',
  });
  if (result.value) {
    return onConfirm();
  }
}

async function promptAppRestart(onConfirm: () => unknown) {
  const result = await Dialog.confirm({
    title: 'Restart Strive',
    message: `Restarting Arena Strive can help fix some connectivity issues.\n\nWould you like to restart now?`,
    okButtonTitle: 'Restart',
    cancelButtonTitle: 'Cancel',
  });
  if (result.value) {
    return onConfirm();
  }
}
