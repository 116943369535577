import { isBefore, startOfToday } from 'date-fns';
import create from 'zustand';

import { useHomepage } from '@arena-labs/strive2-coaching';
import { $API } from '@strive/api';

import { ACIPostResponse } from './aci-form.machine';

type ACIGateStoreActions = {
  handleStartCheckIn: () => void;
  handleACISubmitted: (results: ACIPostResponse) => void;
  handleResultsClosed: () => void;
  openToResults: () => void;
  reset: () => void;
  trigger: () => void;
};

type ACIGateStore =
  | {
      state: 'open' | 'welcome' | 'form';
      actions: ACIGateStoreActions;
      results: null;
    }
  | {
      state: 'results';
      actions: ACIGateStoreActions;
      results: ACIPostResponse;
    };

const useACIGateStore = create<ACIGateStore>((set, get) => ({
  state: 'open',
  results: null,
  actions: {
    handleStartCheckIn: () => {
      set({ state: 'form', results: null });
    },
    handleACISubmitted: (results) => {
      set({ state: 'results', results });
    },
    handleResultsClosed: () => {
      set({ state: 'open', results: null });
    },
    openToResults: () => {
      set({ state: 'results' });
    },
    reset: () => {
      set({ state: 'open', results: null });
    },
    trigger: () => {
      set({ state: 'welcome', results: null });
    },
  },
}));

function useACITrigger() {
  const [homepage] = useHomepage();
  const userQuery = $API.useGetUserProfile();
  const lastAci = homepage?.recent_aci_timestamp;

  const wearable = userQuery.data?.profile?.wearable?.[0];
  const hasSyncedToday = wearable?.is_synced_today;
  const hasValidWearable = wearable?.status === 'valid';

  const forceAci =
    homepage?.learning_session?.aci?.force_aci ||
    homepage?.aci?.force_aci ||
    (lastAci && isBefore(lastAci, startOfToday())) ||
    !hasValidWearable ||
    !hasSyncedToday;

  return forceAci;
}

export function useACIGate() {
  const forceAci = useACITrigger();
  const storeState = useACIGateStore((state) => state.state);
  const actions = useACIGateStore((state) => state.actions);
  const results = useACIGateStore((state) => state.results);

  const state = forceAci && storeState === 'open' ? 'welcome' : storeState;

  return {
    state,
    actions,
    results,
  };
}
