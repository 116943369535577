import * as React from 'react';

export function UserNullRecharge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.8999 43.5757C35.214 43.5757 43.5752 35.2145 43.5752 24.9004C43.5752 14.5863 35.214 6.2251 24.8999 6.2251C14.5858 6.2251 6.22461 14.5863 6.22461 24.9004C6.22461 35.2145 14.5858 43.5757 24.8999 43.5757Z"
        stroke={'currentColor'}
        stroke-width="4.15006"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.0132 33.2004C28.0132 34.9194 26.6196 36.3129 24.9006 36.3129C23.1816 36.3129 21.7881 34.9194 21.7881 33.2004C21.7881 31.4814 23.1816 30.0878 24.9006 30.0878C26.6196 30.0878 28.0132 31.4814 28.0132 33.2004Z"
        fill={'currentColor'}
      />
      <path
        d="M24.9004 24.9004L24.9004 16.6003"
        stroke={'currentColor'}
        stroke-width="4.15006"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

const MemoUserNullRecharge = React.memo(UserNullRecharge);
export default MemoUserNullRecharge;
