import React from 'react';
import {
  BoxProps,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';

import { ArrowUp, useResponsive } from '@arena-labs/strive2-ui';

import { useSetupActor } from './hooks';
import { SetupBranding } from './setup-branding';

type SetupIntroProps = {
  onBack?: React.MouseEventHandler<HTMLAnchorElement>;
} & BoxProps;

export function SetupIntro({ onBack }: SetupIntroProps) {
  const setupActor = useSetupActor();
  const onContinue = () => setupActor.send({ type: 'Next' });
  const rs = useResponsive();
  return (
    <Flex direction="column" px="6" pt={4} gap="4" h="full">
      <Flex gap="4" align="center">
        <SetupBranding />
      </Flex>

      <Image
        my="auto"
        src="/images/striveware/striveware-power-case.webp"
        alt="StriveWare with case"
        mx="auto"
        w={rs({ base: '90%', xs: '70%' })}
      />
      <Text
        textStyle={rs({ base: 'h2', xs: 'h3' })}
        as="h1"
        color="neutral.white"
      >
        <div>Connect Wirelessly,</div>
        <div>Stay in Sync.</div>
      </Text>

      <IconButton
        isRound
        aria-label="continue"
        icon={<Icon as={ArrowUp} boxSize="12" />}
        onClick={onContinue}
        ml="auto"
        transform="rotate(90deg)"
        color="neutral.white"
      />
    </Flex>
  );
}
