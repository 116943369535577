import { Box } from '@chakra-ui/react';
import { useActorRef, useSelector } from '@xstate5/react';
import { match } from 'ts-pattern';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { JStyleDeviceConnected } from '@strive/jstyle';
import { StrivewareContext, strivewarePairingMachine } from '@strive/wearable';

// import { StrivewareHeader } from '../striveware-header';
// import { StrivewareLayout } from '../striveware-layout';
import { StrivewareSetupContext, useInitialDevicePairingStats } from './hooks';
import { SetupCharging } from './setup-charging';
import { SetupIntro } from './setup-intro';
import { SetupMultipleDevices } from './setup-multiple-devices';
import { SetupNoDevices } from './setup-no-devices';
import { SetupScanning } from './setup-scanning';
import { SetupSingleDevice } from './setup-single-device';

export type StrivewareSetupProps = {
  onCancel?: () => void;
  onComplete?: () => void;
};

export function StrivewareSetup(props: StrivewareSetupProps) {
  const strivewareActor = StrivewareContext.useActorRef();
  const analytics = useAnalytics();
  const [pairingStats, setPairingStats] = useInitialDevicePairingStats();
  const setupActor = useActorRef(
    strivewarePairingMachine.provide({
      actions: {
        onDevicePaired: (
          _: unknown,
          { device }: { device: JStyleDeviceConnected },
        ) => {
          if (pairingStats.data) {
            const now = new Date();

            analytics.logEvent(
              GeneralTrackingEvent.UserInitiatedStrivewarePairingStats,
              {
                ...pairingStats.data,
                pairingSuccessTime: now,
              },
            );
            alert('pairing success and im sending the stats');
            setPairingStats.mutateAsync({
              startedPairingTime: null,
              pairingSuccessTime: null,
              singleDeviceFound: null,
              multipleDevicesFound: null,
              noDevicesFound: null,
              pairingFailedTime: null,
            });
          }

          strivewareActor.send({ type: 'Pair Device', device });

          props.onComplete?.();
        },
        onPairingFailed: () => {
          if (pairingStats.data) {
            analytics.logEvent(
              GeneralTrackingEvent.UserInitiatedStrivewarePairingStats,
              {
                ...pairingStats.data,
                pairingFailedTime: new Date(),
              },
            );
          }
          alert('pairing failed and im sending the stats');
          setPairingStats.mutateAsync({
            startedPairingTime: null,
            pairingSuccessTime: null,
            singleDeviceFound: null,
            multipleDevicesFound: null,
            noDevicesFound: null,
            pairingFailedTime: null,
          });
        },
      },
    }),
  );
  const view = useSelector(setupActor, (state) =>
    state.matches('initial')
      ? 'initial'
      : state.matches('charging')
      ? 'charging'
      : state.matches({ 'Finding Devices': 'Quick Scan' }) ||
        state.matches({ 'Finding Devices': 'Extended Scan' })
      ? 'scanning'
      : state.matches({ 'Finding Devices': { Results: 'Single Device' } })
      ? 'results-single'
      : state.matches({ 'Finding Devices': { Results: 'Multiple Devices' } })
      ? 'results'
      : state.matches({ 'Finding Devices': { Results: 'No Devices' } })
      ? 'no-devices'
      : 'initial',
  );

  const handleCancel = (event: React.MouseEvent) => {
    if (props.onCancel) {
      event.preventDefault();
      props.onCancel();
    }
  };

  // const returnToPairingFromNoDevices = () => {
  //   const now = new Date();
  //   if (pairingStats.data) {
  //     setPairingStats.mutate({
  //       ...pairingStats.data,
  //       startedPairingTime: now,
  //     });
  //   }
  // };

  // const returnToPairingFromMultipleDevices = () => {
  //   const now = new Date();
  //   const multipleDevicesFound = pairingStats.data?.multipleDevicesFound ?? [];
  //   if (pairingStats.data) {
  //     setPairingStats.mutate({
  //       ...pairingStats.data,
  //       multipleDevicesFound: [...multipleDevicesFound, now],
  //     });
  //   }
  // };

  return (
    <StrivewareSetupContext.Provider value={setupActor}>
      <Box h={'65vh'}>
        {match(view)
          .with('initial', () => <SetupIntro onBack={handleCancel} />)
          .with('charging', () => <SetupCharging />)
          .with('scanning', () => <SetupScanning />)
          .with('results', () => <SetupMultipleDevices />)
          .with('results-single', () => <SetupSingleDevice />)
          .with('no-devices', () => <SetupNoDevices />)

          .exhaustive()}
      </Box>
    </StrivewareSetupContext.Provider>
  );
}
