import React from 'react';
import { useUnmount } from 'react-use';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import {
  AciContext,
  ACIModal,
  LegacyACIModal,
  useACIGate,
} from '@arena-labs/strive2-data';
import { ChevronLeftIcon, CloseIcon, Icon } from '@arena-labs/strive2-ui';
import { IfWearable } from '@strive/wearable';

import { AppBanner } from '../layout/app-banner';
import { LogPracticeCard } from '../practice/log-practice-card';
import { ContactModal } from '../support/contact-modal';

export function AppACIModal() {
  const aciActor = AciContext.useActorRef();
  const isOpen = AciContext.useSelector((state) =>
    state.matches('Open') ? true : false,
  );
  const closeAci = () => aciActor.send({ type: 'close' });

  const supportDisclosure = useDisclosure();

  //legacy aci below
  const { state, actions } = useACIGate();
  const queryClient = useQueryClient();
  useUnmount(() => {
    if (state !== 'open') {
      actions.reset();
    }
  });

  return (
    <>
      <IfWearable mode="sensorless">
        <LegacyACIModal
          isOpen={state === 'form' || state === 'results'}
          onSuccess={(results) => {
            queryClient.invalidateQueries();
            actions.handleACISubmitted(results);
          }}
          onClose={() => actions.reset()}
        />
      </IfWearable>
      <IfWearable mode={['partner', 'striveware']}>
        <>
          <ContactModal {...supportDisclosure} />
          <ACIModal
            isOpen={isOpen}
            onClose={closeAci}
            onContactSupport={() => supportDisclosure.onOpen()}
            logPracticeCard={(practice) => {
              return (
                <LogPracticeCard
                  onNavigate={() => aciActor.send({ type: 'close' })}
                  practice={practice}
                />
              );
            }}
            banner={(onNavigateBack?: () => void) => (
              <AppBanner color={'neutral.black'}>
                {onNavigateBack && (
                  <Button
                    variant={'unstyled'}
                    textAlign={'left'}
                    p={0}
                    size={'xs'}
                    onClick={() => onNavigateBack()}
                  >
                    <Icon as={ChevronLeftIcon} boxSize={4} />
                  </Button>
                )}

                <Text
                  textStyle={'h4'}
                  mx={'auto'}
                  w={'full'}
                  gridColumn={2}
                  gridColumnStart={2}
                >
                  Arena Check-In
                </Text>
                <Button
                  gridColumn={3}
                  onClick={closeAci}
                  variant={'unstyled'}
                  textAlign={'right'}
                  p={0}
                  size={'xs'}
                >
                  <Icon as={CloseIcon} boxSize={6} />
                </Button>
              </AppBanner>
            )}
          />
        </>
      </IfWearable>
    </>
  );
}
