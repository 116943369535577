import { useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { startOfDay } from 'date-fns';
import { useAnimate } from 'framer-motion';

import {
  LottieGif,
  StreakFlame,
  StreakMiss,
  ZStack,
} from '@arena-labs/strive2-ui';

export function GeneratingInsights({
  currentStreak,
  maxStreak,
  streakRecord,
  totalACIs,
  shouldAnimate = true,
  onClose,
}: {
  currentStreak: number | '-';
  totalACIs: number | '-';
  maxStreak: number | '-';
  streakRecord: { date: Date; status: 'hit' | 'miss' | 'blank' }[];
  shouldAnimate?: boolean;
  onClose?: () => void;
}) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (!shouldAnimate) return;
    // Sequential animations with slightly longer delays
    animate(
      '#streakFlame',
      { opacity: 1, scale: 1, x: ['-20px', '0px'] },
      { duration: 0.3, delay: 0.4 },
    );
    animate(
      '#stats',
      { opacity: 1, x: ['-20px', '0px'] },
      { duration: 0.3, delay: 0.8 },
    );
    animate(
      '#checkinRow',
      { opacity: 1, x: ['-20px', '0px'] },
      { duration: 0.3, delay: 1.2 },
    );
  }, [animate, shouldAnimate]);

  return (
    <Flex
      h={'full'}
      id="content"
      direction="column"
      align="center"
      gap={4}
      flex={1}
      ref={scope}
      py={6}
      my={6}
    >
      <Box mb={6} opacity={shouldAnimate ? 0 : 1} id="streakFlame">
        <ZStack mx={'auto'}>
          <Icon as={StreakFlame} w={'124px'} h={'auto'} color={'primary.500'} />
          <Center h={'full'} w={'full'}>
            <Text
              textStyle={'h1'}
              fontSize={'48px'}
              mt={9}
              color={'neutral.white'}
            >
              {currentStreak}
            </Text>
          </Center>
        </ZStack>
        <Text
          textStyle={'h5'}
          w={'full'}
          textAlign={'center'}
          mt={'2'}
          color={'primary.500'}
        >
          {' '}
          day streak!
        </Text>
      </Box>

      <Grid
        id="stats"
        templateColumns="repeat(2, 1fr)"
        gap={'9px'}
        opacity={shouldAnimate ? 0 : 1}
        w={'full'}
        textAlign={'center'}
      >
        <Flex
          direction={'column'}
          gap={1}
          bg={'neutralAlpha.50'}
          rounded={'card'}
          p={3}
          w={'full'}
        >
          <Text textStyle={'copy_extra_small'} color={'neutral.600'}>
            Total ACIs
          </Text>
          <Text textStyle={'copy_bold'}>{totalACIs}</Text>
        </Flex>
        <Flex
          direction={'column'}
          gap={1}
          bg={'neutralAlpha.50'}
          rounded={'card'}
          p={3}
          w={'full'}
        >
          <Text textStyle={'copy_extra_small'} color={'neutral.600'}>
            Longest Streak
          </Text>
          <HStack alignItems={'center'} mx={'auto'}>
            <Text textStyle={'copy_bold'}>{maxStreak}</Text>
            <Text textStyle={'copy_small_thin'} mt={1}>
              days
            </Text>
          </HStack>
        </Flex>
      </Grid>

      <Flex
        id="checkinRow"
        direction={'column'}
        bg={'neutralAlpha.50'}
        opacity={shouldAnimate ? 0 : 1}
        rounded={'card'}
        gap={2}
        p={3}
      >
        <Flex justify={'space-between'}>
          {streakRecord.map((entry) => {
            const dayLetter = entry.date.toLocaleString('en-US', {
              weekday: 'short',
            })[0];
            const isToday =
              startOfDay(entry.date).getTime() ===
              startOfDay(new Date()).getTime();

            return (
              <VStack key={entry.date.toISOString()}>
                <Circle size="26px" bg="neutral.300">
                  {entry.status === 'hit' ? (
                    <Icon as={StreakFlame} boxSize="20px" color="primary.500" />
                  ) : entry.status === 'miss' ? (
                    <Icon as={StreakMiss} boxSize="24px" color="negative.500" />
                  ) : null}
                </Circle>
                <Text
                  textStyle={'copy_small'}
                  color={isToday ? 'primary.400' : 'inherit'}
                >
                  {dayLetter}
                </Text>
              </VStack>
            );
          })}
        </Flex>
        <Divider borderColor={'neutral.400'} mx={-3} px={3} />
        <Text w={'full'} textAlign={'center'} textStyle={'copy_small'}>
          A streak counts how many days you&apos;ve checked in in a row.
        </Text>
      </Flex>

      {shouldAnimate && (
        <HStack mt={'100px'}>
          <Box w={'50px'} h={'auto'}>
            <LottieGif animationAlias="animationLoading" />
          </Box>
          <Text textStyle={'copy_small'}>Reviewing Your Data</Text>
        </HStack>
      )}
      {onClose && (
        <Button
          variant="primary"
          w={'full'}
          mt={'auto'}
          onClick={() => onClose()}
        >
          Close
        </Button>
      )}
    </Flex>
  );
}
