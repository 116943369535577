import { Box } from '@chakra-ui/react';

import { useFadeEntryFadeExit } from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';

import { GeneratingInsights } from './checkin-insights/generating-insights';

export function StreakInfo({ onClose }: { onClose?: () => void }) {
  const { scope } = useFadeEntryFadeExit();
  const { data: streakData } = $API.useGetStreakData();
  return (
    <Box ref={scope} h={'full'}>
      <GeneratingInsights
        currentStreak={streakData?.streak.current ?? '-'}
        totalACIs={streakData?.streak.total_acis ?? '-'}
        maxStreak={streakData?.streak.max ?? '-'}
        streakRecord={streakData?.history ?? []}
        shouldAnimate={false}
        onClose={onClose}
      />
    </Box>
  );
}
