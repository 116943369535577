import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertProps,
  AlertTitle,
  BoxProps,
  Flex,
} from '@chakra-ui/react';

import { useResponsive } from '../utils/use-responsive';

type AlertBoxContent = string | React.ReactNode;

export type AlertBoxProps = {
  showIcon?: boolean | BoxProps['as'];
} & (
  | {
      title: AlertBoxContent;
      description?: AlertBoxContent;
    }
  | {
      title?: AlertBoxContent;
      description: AlertBoxContent;
    }
) &
  AlertProps;

export function AlertBox({
  showIcon,
  title,
  description,
  ...props
}: AlertBoxProps) {
  const rs = useResponsive();
  return (
    <Alert
      flexDirection="column"
      overflow="unset"
      gap={rs({ base: 2, xs: 0 })}
      py={rs({ base: 2, xs: 1 })}
      {...props}
    >
      {title ? (
        <Flex>
          {showIcon === true ? (
            <AlertIcon />
          ) : showIcon ? (
            <AlertIcon as={showIcon} />
          ) : null}
          <AlertTitle>{title}</AlertTitle>
        </Flex>
      ) : null}
      {description ? (
        <AlertDescription w="full" lineHeight={rs({ base: 1.5, xs: 1.2 })}>
          {description}
        </AlertDescription>
      ) : null}
    </Alert>
  );
}
