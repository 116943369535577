import { useState } from 'react';
import NextLink from 'next/link';
import {
  Box,
  Center,
  Flex,
  Grid,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';

import { ToolKitTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import { PillarPractice } from '@arena-labs/shared-models';
import { usePracticeCache } from '@arena-labs/strive2-content';
import {
  CheckIcon,
  ChevronRightIcon,
  pluralize,
  PracticeIcon,
} from '@arena-labs/strive2-ui';
import { queryClient } from '@strive/api';
import { haptics } from '@strive/device';

import { useLogPractice } from './use-log-practice';

export type LogPracticeCardProps = {
  practice: PillarPractice;
  isChecked?: boolean;
  onMutate?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
  onNavigate?: () => void;
  showStats?: boolean;
  disclosure?: ReturnType<typeof useDisclosure>;
  displayStyle?: 'simple';
};
export function LogPracticeCard({
  practice,
  isChecked = practice?.completed_today,
  onMutate,
  onSuccess,
  onError,
  showStats = true,
  disclosure,
  displayStyle,
  onNavigate,
}: LogPracticeCardProps) {
  const defaultDisclosure = useDisclosure();
  const extraInformation = disclosure ?? defaultDisclosure;
  const setPracticeCache = usePracticeCache();
  const updatePractice = useLogPractice({ retry: 3 });
  const analytics = useAnalytics({
    practiceSlug: practice?.slug,
    practiceName: practice?.title,
  });

  const [imageLoaded, setImageLoaded] = useState(false);

  function handleLinkClick() {
    setPracticeCache(practice);
    analytics.logEvent(ToolKitTrackingEvent.ToolOpened);
    onNavigate && onNavigate();
  }

  const practiceIsChecked =
    isChecked || updatePractice.isLoading || updatePractice.isSuccess;

  const handleSubmit = async () => {
    if (updatePractice.isLoading) {
      return;
    }
    try {
      analytics.logEvent(ToolKitTrackingEvent.ToolCompleted, {
        practice: practice.slug,
      });
      onMutate?.();
      await updatePractice.mutateAsync(
        { practice: practice.slug },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
            onSuccess && onSuccess();
          },
          onError,
        },
      );
    } catch (e) {
      console.error(`Error logging practice ${practice.slug}`, e);
      captureException(e);
    }
  };

  return (
    <Box
      borderRadius="card"
      overflow={'hidden'}
      border={'1px solid'}
      borderColor={'neutralAlpha.200'}
    >
      <Flex
        direction={'column'}
        position={'relative'}
        w="full"
        borderRadius="card"
        pl={1}
        pr={4}
        pt="3"
        pb={extraInformation.isOpen ? 1 : 3}
      >
        {displayStyle !== 'simple' && (
          <Image
            position={'absolute'}
            top={'-135px'}
            left={'0'}
            src={practice.background_image ?? ''}
            alt={practice.title}
            width={'auto'}
            height={'auto'}
            zIndex={10}
            opacity={imageLoaded ? 1 : 0}
            transition={'opacity 0.25s ease-in'}
            onLoad={() => {
              setImageLoaded(true);
            }}
          />
        )}
        <Box
          position={'absolute'}
          top={0}
          left={0}
          h={'full'}
          w="full"
          bg={'neutral.white'}
          opacity={0.85}
          zIndex={11}
        />

        <Grid
          zIndex={12}
          w="full"
          templateColumns=" 40px 1fr 40px"
          alignItems={'center'}
          gap={2}
        >
          {displayStyle === 'simple' ? (
            <Text textStyle={'copy_bold'} gridColumn={2}>
              {practiceIsChecked ? 'Completed Today' : 'Complete Today?'}
            </Text>
          ) : (
            <>
              <Center h="auto" w={'full'} ml={2}>
                <PracticeIcon slug={practice.slug} pointerEvents="none" />
              </Center>

              <Flex direction="column" justify="center" w="full" pl="2" gap="0">
                <Link
                  as={NextLink}
                  href={`/practices/${practice.slug}`}
                  onClick={() => handleLinkClick()}
                >
                  <HStack gap={'2px'}>
                    <Text textStyle={'copy'}>{practice.short_title}</Text>
                    <Icon
                      as={ChevronRightIcon}
                      boxSize={'10px'}
                      strokeWidth={'1px'}
                      color={'neutral.600'}
                    />
                  </HStack>
                </Link>

                {showStats ? (
                  <Text textStyle={'copy_extra_small'}>
                    {practice.log_count}&nbsp;
                    {`${pluralize(practice.log_count, 'Completion')}`}
                    {practice.streak > 0 ? (
                      <span>
                        &nbsp; &#x2022; &nbsp;
                        {practice.streak}&nbsp;
                        {`${pluralize(practice.streak, 'Day')}`}&nbsp;Streak
                      </span>
                    ) : null}
                  </Text>
                ) : null}
              </Flex>
            </>
          )}
          <Box
            as="button"
            aria-label="I have completed this practice"
            aria-pressed={practiceIsChecked}
            placeSelf="center"
            h={'30px'}
            w={'30px'}
            mt={'-2px'}
            bg={practiceIsChecked ? 'primary.400' : 'transparent'}
            border="3px solid"
            borderRadius="full"
            onClick={() => {
              if (!practiceIsChecked) {
                haptics.impact('LIGHT');
                handleSubmit();
              }
            }}
            borderColor="primary.400"
          >
            {practiceIsChecked && (
              <Icon
                as={CheckIcon}
                boxSize={6}
                strokeWidth="4px"
                color="neutral.white"
              />
            )}
          </Box>
        </Grid>
      </Flex>
    </Box>
  );
}
