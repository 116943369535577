import { useState } from 'react';
import {
  Box,
  Button,
  chakra,
  Fade,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';

import { useHomepage } from '@arena-labs/strive2-coaching';
import {
  ProductEducationPopover,
  useEducationPopoverViewed,
  useGradient,
  useResponsive,
} from '@arena-labs/strive2-ui';

import { AciCtaWidget } from '../../aci/aci-cta-widget';
import { AppLayout } from '../../layout/app-layout';
import { HomeBanner } from '../../layout/home-banner/home-banner';

export function HomeHub({
  practiceSetName,
  willSelectPracticeSet,
  userName,
  practiceName,
  practiceDescription,
  bgImagePath,
}: {
  practiceDescription: string;
  practiceSetName: string;
  willSelectPracticeSet: boolean;
  bgImagePath: string | undefined | null;
  userName?: string | undefined;
  practiceName?: string | undefined | null;
}) {
  const rs = useResponsive();
  const bgGradient = useGradient('background');
  const [_, { send }] = useHomepage();
  const hasViewedACIToolTip = useEducationPopoverViewed('ACI_Two')[0];
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <AppLayout
      banner={
        <HomeBanner shadow={'none'} title={practiceSetName} bg={bgGradient} />
      }
    >
      <Fade in style={{ height: '100%' }}>
        <Flex
          zIndex={-1}
          direction={'column'}
          h="full"
          pt={rs({ xs: 2, base: 4 })}
          pb="6"
          px="4"
          gap={'32px'}
        >
          <Text textStyle={'h4'} display={'flex'} flexDir={'column'}>
            {/* this prob breaks a test */}
            <span data-testid={'greeting-text'}>
              {getGreeting()}, {userName ?? 'welcome'}.
            </span>
          </Text>

          <Flex w={'full'} gap={4} direction={'column'} px={2}>
            <Text textStyle={'copy'} color={'neutral.700'} mr={'auto'}>
              Daily Action
            </Text>

            <ProductEducationPopover
              id={'Choose_Challenge'}
              placement={'bottom'}
              shouldRender={willSelectPracticeSet && hasViewedACIToolTip}
              title={'Select Next Challenge'}
              body={
                'The power is yours! What set of tools do you want to unlock next?'
              }
            >
              <VStack
                w={'full'}
                h={'240px'}
                position="relative"
                rounded={'card'}
                border={'1px solid'}
                borderColor={'neutralAlpha.400'}
                overflow={'hidden'}
              >
                {!imageLoaded && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bg="neutralAlpha.50"
                    zIndex={-1}
                  />
                )}
                <Image
                  src={
                    willSelectPracticeSet
                      ? '/images/learning-journey/select-next-challenge.webp'
                      : bgImagePath ?? ''
                  }
                  alt=""
                  position="absolute"
                  top={willSelectPracticeSet ? 0 : '-25%'}
                  left={0}
                  h={'auto'}
                  w={'full'}
                  opacity={imageLoaded ? 1 : 0}
                  transition="opacity 1s"
                  onLoad={() => setImageLoaded(true)}
                  loading="lazy"
                />
                <Spacer />
                <Flex
                  position={'relative'}
                  direction={'column'}
                  w={'full'}
                  rounded={'card'}
                  px={4}
                  py={3}
                  gap={4}
                  filter={'none'}
                >
                  <Box
                    bg={'neutral.white'}
                    opacity={0.85}
                    zIndex={0}
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    position={'absolute'}
                    roundedTop={'card'}
                  />

                  <VStack align={'flex-start'} w={'full'} zIndex={1}>
                    <HStack w={'full'} pr={2}>
                      <Text
                        textStyle={'h4'}
                        color={'neutral.black'}
                        flexWrap={'nowrap'}
                      >
                        {willSelectPracticeSet
                          ? 'Unlock New Lessons'
                          : `${practiceName}`}
                      </Text>
                      <Spacer />
                      {!willSelectPracticeSet && (
                        <chakra.span
                          ml={'auto'}
                          textStyle={'copy_small_thin'}
                          color={'neutral.800'}
                        >
                          {' '}
                          5 min
                        </chakra.span>
                      )}
                    </HStack>
                    <Text textStyle={'copy_small_thin'} color={'neutral.800'}>
                      {willSelectPracticeSet
                        ? 'You’re ready for the next set of tools, called challenges. Choose from the list to start your lesson, or ask your coach for a personalized recommendation.'
                        : practiceDescription}
                    </Text>
                  </VStack>

                  <Button
                    onClick={() => {
                      send('Close Landing');
                    }}
                    variant={'primary'}
                  >
                    {willSelectPracticeSet
                      ? 'Select Challenge'
                      : `Start Lesson`}
                  </Button>
                </Flex>
              </VStack>
            </ProductEducationPopover>

            <AciCtaWidget />
          </Flex>
        </Flex>
      </Fade>
    </AppLayout>
  );
}

function getGreeting() {
  const currentHour = new Date().getHours();
  return currentHour < 12
    ? 'Good Morning'
    : currentHour < 18
    ? 'Good Afternoon'
    : 'Good Evening';
}
