import { Button, Flex, Image, Text } from '@chakra-ui/react';

import { Wearable } from '@strive/api';

export type AuthorizeWearableProps = {
  url: string;
  provider: Wearable['provider'];
  status: Wearable['status'];
};

export function AuthorizeWearable({
  url,
  provider,
  status,
}: AuthorizeWearableProps) {
  const isReconnect = status === 'revoked' || status === 'expired';
  const providerName = provider === 'oura' ? 'Oura Ring' : 'Whoop';
  const providerImagePath = `/images/${
    provider === 'oura' ? 'oura/oura-ring' : 'whoop/whoop-blue'
  }.webp`;
  const actionVerb = isReconnect ? 'Reconnect' : 'Connect';

  return (
    <Flex
      direction="column"
      px="4"
      py="6"
      gap="3"
      bg="gray.800"
      rounded="card"
      textAlign="center"
    >
      <Text textStyle={'h3'}>{actionVerb} your Device</Text>
      <Image src={providerImagePath} alt="" w="132px" alignSelf="center" />
      <Text textStyle={'copy_small'} color="neutral.400">
        Unlock your data by {actionVerb.toLowerCase()}ing your {providerName}{' '}
        now.
      </Text>
      <Button as="a" target="_blank" href={url} w="full" variant="primary">
        {actionVerb} To {providerName.split(' ')[0]} ↗️
      </Button>
    </Flex>
  );
}
