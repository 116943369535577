import { Button, Center, Flex, Icon, Image, Text } from '@chakra-ui/react';

import { useAnalytics } from '@arena-labs/analytics';
import {
  NoConnectionIcon,
  PartnerDeviceIcon,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { IfWearable, useStrivewareBattery } from '@strive/wearable';

import { StrivewareBattery } from './striveware/striveware-battery';
import { useWearableHub } from './use-wearable-hub';

export function WearableButton() {
  const { onOpen } = useWearableHub();
  const analytics = useAnalytics();
  return (
    <IfWearable>
      {({ mode }) => {
        return (
          <Button
            // as={NextLink}
            // href={`/wearable/${mode}`}
            aria-label={'My Wearable'}
            shadow={'1dp'}
            px={2}
            py={6}
            rounded={'full'}
            _hover={{
              textDecoration: 'none',
            }}
            _active={{
              shadow: '1dp',
            }}
            _focus={{
              shadow: '1dp',
            }}
            _selected={{
              shadow: '1dp',
            }}
            onClick={() => {
              analytics.user('click', {
                target: 'wearable-button',
                wearable: mode,
              });
              onOpen();
            }}
          >
            <Center h={'full'}>
              {mode === 'striveware' ? (
                <StrivewareButton />
              ) : (
                <Icon as={PartnerDeviceIcon} color="icon.primary" boxSize={8} />
              )}
            </Center>
          </Button>
        );
      }}
    </IfWearable>
  );
}

function StrivewareButton() {
  const batteryQuery = useStrivewareBattery();

  const notConnected = (
    <Icon as={NoConnectionIcon} color="neutral.400" boxSize={5} />
  );

  return (
    <Flex gap="2" align="center" px="1">
      <WaitForQuery
        query={batteryQuery}
        error={notConnected}
        loading={notConnected}
      >
        {({ batteryLevel, batteryStatus }) => {
          const color = {
            low: 'negative.200',
            medium: 'warning.200',
            high: 'white',
          }[batteryStatus];
          return (
            <Flex direction="column" gap="1">
              <Text textStyle={'copy_extra_small'} color={color}>
                {batteryLevel}%
              </Text>
              <StrivewareBattery level={batteryLevel} size="2.5" />
            </Flex>
          );
        }}
      </WaitForQuery>
      <Image
        ml={'auto'}
        src={'./images/striveware/black-ring-grey-light.webp'}
        w={9}
        alt=""
      />
    </Flex>
  );
}
